'use client';

import { FormEvent, useState } from 'react';
import { toast } from 'sonner';

import { Button, buttonVariants } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

import Providerbuttons from './providers-buttons';

export const Authorization = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const handleValidPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;

    const enteredPassword = form.password.value;
    console.log('validPassword', enteredPassword);

    const isValidPassword =
      enteredPassword === process.env.NEXT_PUBLIC_PASSWORD;

    if (isValidPassword) {
      setValidPassword(true);
    } else {
      toast.error('Senha inválida');
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger
          className={buttonVariants({ variant: 'default', size: 'lg' })}
        >
          Entrar
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Autorização</DialogTitle>
            <DialogDescription>
              {validPassword
                ? 'Precisa logar com o Google'
                : 'Insira a senha para continuar'}
            </DialogDescription>
          </DialogHeader>

          {!validPassword ? (
            <form
              onSubmit={(e) => handleValidPassword(e)}
              className="space-y-6"
            >
              <Input type="password" placeholder="Password" name="password" />
              <Button type="submit" className="w-full">
                Entrar
              </Button>
            </form>
          ) : (
            <>
              <div className="flex w-full items-center justify-center">
                <Providerbuttons />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
