'use client';
import { Provider } from '@supabase/supabase-js';
import { useState } from 'react';
import { toast } from 'sonner';

import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import useSupabaseClient from '@/lib/supabase/client';

export default function Providerbuttons() {
  const [loading, setLoading] = useState(false);
  const supabase = useSupabaseClient();
  const url = `${location.origin}/auth/callback`;

  const signInWithProvider = async (provider: Provider) => {
    let toastId: string | number = '';

    setLoading(true);
    toastId = toast.loading('Iniciando sessão', { id: toastId });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: url,
      },
    });
    toast.dismiss(toastId);
    setLoading(false);
  };

  return (
    <Button
      variant="default"
      disabled={loading}
      onClick={() => signInWithProvider('google')}
    >
      {loading ? (
        <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <Icons.google className="mr-2 h-4 w-4" />
      )}{' '}
      Google
    </Button>
  );
}
